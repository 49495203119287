import React from 'react';
import someImage from '../img/gbms.png';

const ProjectGBMS = () => {
    return (
        <div className="project-contents">
            <p>
                Creating an app to be used on cargo ships was no easy feat. A lot of jargon was being used by the client and on the websites of competitors. Before I could continue with the design, I had to educate myself. What does everything mean? In what environment will this app be used? This is why I visited a cargo ship in the marine of Rotterdam. This was an amazing experience, where I could talk to the people working on the boat, interview the target audience and see exactly where the app will be used.
                <br />
                <br />
                Designing still took a lot of trial and error, the content is very field-specific and this wasn’t a case where I could start designing based off previous experience. This however also made it a very exciting project to work on.
                <br />
                <br />
                Something that also made this project more challenging is the fact that a lot of content was not available yet while I was making the design. The only information that was given to me were the 3D models and the fact that there would be warnings and a lot of information for each container. During the project more and more information became available so that I could add it into the design and adjust where needed to keep the focus on the most important information.
                <br />
                <br />
                Through lots of communication with the client, sharing the designs, and in-between usability testing, I was able to come up with a design that met the needs of the client and of course the target audience.
                <br />
                <br />
                Being that the app will be used on a cargo ship made for some interesting design choices. A black background with low-saturation colors because “even a tiny light from a charger will be too much light at sea during the night”.
                <br />
                <br />
                This project was very educational for me as a UX designer, because it shows you very clearly how important it is to cater to your target audience.
                <br />
                <br />
                <i>This project was made while working for Iconica.</i>
                <br />
                <br />
            </p>
            <img src={someImage}/>
            <h3>September 2023 - February 2024</h3>
        </div>
    )
};

export default ProjectGBMS;