import React from 'react';
import someImage from '../img/wintersport-rev-robin.png';

const ProjectRobin2 = () => {
    return (
        <div className="project-contents">
            <p></p>
            <img src={someImage}/>
            <h3>December 2018</h3>
        </div>
    )
};

export default ProjectRobin2;

