import React from 'react';
import someImage from '../img/wellqom.png';

const ProjectWellQom = () => {
    return (
        <div className="project-contents">
            <p>
                I joined this project when my colleague had already created a lo-fi design. After taking over, my job was to make sure the wants and needs of the client were being met and discuss branding with an external company.
                <br />
                <br />
                After that I transformed the app to a hi-fi version. This took many iterations, as this is a very broad app, but keeping in close contact with the client ensured that they were always up to date with and validating the design. Not only does this project concern a mobile app, but also a web back office that needed to be compatible.
                <br />
                <br />
                Finally I delivered a clickable prototype which was tested by the client and some of their staff. Based off this there were a few more iterations after which the development team could start building. During the development process I had to be available for questions, adjust designs based off feedback and help developers understand the flow by keeping the clickable prototype up to date.
                <br />
                <br />
                This is a developing project, meaning new functionalities are added from time to time. It is my job to be flexible and be able to get back into the flow of the app and deliver new designs that fit in with the rest.
                <br />
                <br />
                <i>This project was made while working for Iconica.</i>
                <br />
                <br />
            </p>
            <img src={someImage}/>
            <h3>2022 - Now</h3>
        </div>
    )
};

export default ProjectWellQom;