import React from 'react';
import someImage from '../img/spacex2.jpg';

const ProjectSpaceX = () => {
    return (
        <div className="project-contents">
            <p></p>
            <img src={someImage}/>
            <h3>December 2018</h3>
        </div>
    )
};

export default ProjectSpaceX;

