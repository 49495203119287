import React from 'react';
import someImage from '../img/mockup-dashboard-workload-robin sml.png';

const ProjectRobin3 = () => {
    return (
        <div className="project-contents">
            <p></p>
            <img src={someImage}/>
            <h3>January 2019</h3>
        </div>
    )
};

export default ProjectRobin3;

