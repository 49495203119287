import React from 'react';
import someImage from '../img/beep-for-help.png';

const ProjectBeepForHelp = () => {
    return (
        <div className="project-contents">
            <p>
                For this app I helped with creating and testing out the design. We would talk to the client to discuss their wants and needs and organise days to meet people of the target group. These were the moments where we could gather their wants and needs, do usability testing and create a better understanding of their mental model.
                <br />
                <br />
                During the development process I had to be available for questions, adjust designs based off feedback and help developers understand the flow by keeping the clickable prototype up to date.
                <br />
                <br />
                I also held a presentation to show our client the importance of usability testing and how to conduct these tests. A video of this presentation can be found <a href="https://www.youtube.com/watch?v=rtmYE7c8D1w">here</a>.
                <br />
                <br />
                <i>This project was made while working for Iconica.</i>
                <br />
                <br />
            </p>
            <img src={someImage}/>
            <h3>2022 - Now</h3>
        </div>
    )
};

export default ProjectBeepForHelp;